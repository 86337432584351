@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Niconne:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Norican:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    @apply leading-[normal] m-0;
}
*,
::before,
::after {
    border-width: 0;
}
